<template>
  <div class="row">
    <div class="col-md-12">
        <el-table
            :data="tableData"
            :header-cell-style="{ background:'rgba(212, 216, 226, 0.2)'}"
            style="width: 100%"
            :empty-text="'No Data Available'"
            height="45vh"
            >
            <el-table-column
                label="Code Name"
                prop="code"
                >
            </el-table-column>
            <el-table-column
                label="Service Type"
                prop="service_type"
                >
            </el-table-column>
            <el-table-column
                label="Expiration"
                >
                <template slot-scope="scope">
                    <p> 
                        {{ moment(scope.row.expiry_date).format('L') }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column
                label="Terms"
                >
                <template slot-scope="scope">
                <p> 
                    {{ getPercent(scope.row.percentage) }}
                </p>
            </template>
            </el-table-column>
            <el-table-column
                label="Limits"
                prop="limit"
                >
            </el-table-column>
            <el-table-column
                label="Action"
                width="200">
                <template slot-scope="scope">
                    <button v-if="scope.row.status == 1" class="disapprove" @click="toggleProcomode(scope, 0)" >
                        Disable
                    </button>
                    <button v-else class="approve" @click="toggleProcomode(scope, 1)" >
                        Enable
                    </button>
                </template>
            </el-table-column>
        </el-table>
        <ActionModal ref="actionModal" :payload="payload"/> 
    </div> 
</div>
</template>

<script>
import $ from 'jquery'
import store from '@/state/store.js'
export default {
    props: {
        promoType: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            payload: {
                type: ''
            }
        }
    },
    computed: {
        tableData() {
            return store.getters['admin/promocodes/' + this.promoType]
        }
    },
    methods: {
        getPercent(per) {
            return `${per * 100}% off`
        },
         openActionModal() {
            let element = this.$refs.actionModal.$el
            $(element).modal('show')
        },
        toggleProcomode(message, status){
            this.payload = {
                message: status == 0 ? 'Are you sure you want to disable this promo code?' : 'Are you sure you want to enable this promo code?',
                action: 'togglePromoCode',
                actionMessage: status == 1 ? 'Enable' : 'Disable',
                data: message,
                status: status
            }
            this.openActionModal()
        },
    }
}
</script>

<style scoped>
button{
    outline:none;
    border:none;
    background: transparent;
    background: transparent;
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
}
.disapprove{
    color: red;
}
.approve{
    color:#F7921C
}
</style>