<template>
    <tableComponent :promoType="'expiredPromoCodes'" />
</template>
<script>
import tableComponent from './tableComponent'
import appConfig from '@/app.config'

export default {
    page: {
		title: 'Expired promo codes',
		meta: [{ name: 'description', content: appConfig.description }],
	},
    name:'expired-promo-codes',
    components: {
        tableComponent
    },
}
</script>